import { NextRouter } from 'next/router';
import { ICandidateJobDto } from '@nploy/ui-infrastructure';
import { createShareJobUrl } from './create-share-job-url';

export const setJobQuery = async (
  job: ICandidateJobDto,
  router: NextRouter,
  lang?: string,
) =>
  router.replace(
    {
      query: {
        ...router.query,
        'job-details': createShareJobUrl(job, lang, false),
      },
    },
    undefined,
    { shallow: true },
  );

export const removeJobQuery = () => {};
