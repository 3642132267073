import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveStateType, UiReducerState } from 'store/types';

const initialState: UiReducerState = {
  activeState: 'experience',
  isUserStudent: false,
  isOnboarding: true,
};

export const onboardingSlice = createSlice({
  name: 'onboardingReducer',
  initialState,
  reducers: {
    updateActiveState: (state, action: PayloadAction<ActiveStateType>) => ({
      ...state,
      activeState: action.payload,
    }),
    updateIsUserStudent: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isUserStudent: action.payload,
    }),
    updateIsOnboarding: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isOnboarding: action.payload,
    }),
  },
});

export const { updateActiveState, updateIsUserStudent, updateIsOnboarding } =
  onboardingSlice.actions;

export const onboardingReducer = onboardingSlice.reducer;
