import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';
import { IJobRoleDto } from '@nploy/ui-infrastructure-recruiters';

export const JOB_ROLES_API = 'JOB_ROLES_API';

export const jobRolesApi = createApi({
  reducerPath: JOB_ROLES_API,
  tagTypes: [JOB_ROLES_API],
  baseQuery: async (args) => {
    const { data } = await axios.request(args);
    return {
      data,
    };
  },
  endpoints: (build) => ({
    getJobRoles: build.query<IJobRoleDto[], void>({
      query: () => ({
        url: 'list/job-roles',
        method: 'GET',
        params: {
          all: 1,
        },
      }),
      providesTags: [JOB_ROLES_API],
    }),
  }),
});

export const { useGetJobRolesQuery } = jobRolesApi;
