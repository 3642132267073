import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';
import { IJobCategoryDto } from '@nploy/ui-infrastructure';

export const JOB_CATEGORIES_API = 'JOB_CATEGORIES_API';

export const jobCategoriesApi = createApi({
  reducerPath: JOB_CATEGORIES_API,
  tagTypes: [JOB_CATEGORIES_API],
  baseQuery: async (args) => {
    const { data } = await axios.request(args);
    return {
      data,
    };
  },
  endpoints: (build) => ({
    getJobCategories: build.query<IJobCategoryDto[], string>({
      query: () => ({
        url: 'list/job-occupations',
        method: 'GET',
      }),
      providesTags: [JOB_CATEGORIES_API],
    }),
  }),
});

export const { useGetJobCategoriesQuery } = jobCategoriesApi;
