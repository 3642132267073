import { Translate } from 'next-translate';
import moment from 'moment/moment';

export const getPostedAgoTime = (publishedDate: string, t: Translate) => {
  try {
    const days = moment().diff(publishedDate, 'days');

    if (typeof days !== 'number' || days < 0) return '';

    const xDaysAgo = t('dayAgo', { count: days });

    return `${t('job.jobPosted')} ${xDaysAgo}`;
  } catch (error) {
    return '';
  }
};
