export enum CandidatesRoutes {
  initialRoute = '/',
  jobs = '/jobs',
  topCompanies = '/companies',
  resume = '/resume',
  activity = '/activity',
  settings = '/settings',
  messages = '/messages',
  onboarding = '/onboarding',
}
