var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"97fcdefb94ac2715abb54a05ab77d5786ba9f148"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_NEXT_DSN,
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 150,
  debug: false,
  autoSessionTracking: true,
  environment: process.env.SENTRY_NEXT_ENVIRONMENT,
});
