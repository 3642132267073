import LinkedInTag from 'react-linkedin-insight';

export const LINKEDIN_PARTNER_ID = process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID;

const EVENT_NAME_TO_CONVERSION_ID_MAP = {
  login_web: '3672713',
  create_account_web: '3672793',
  create_resume_web: '3672721',
  build_profile_web: '3672737',
  complete_resume_web: '3672729',
  apply_web: '3672745',
  discard_web: '3672753',
  download_resume_web: '3672761',
  enter_chat_web: '',

  accounting_finance_and_statistics_web: '3672769',
  administration_and_secretarial_web: '',
  architecture_and_construction_web: '',
  business_management_and_consulting_web: '',
  catering_hospitality_tourism_web: '',
  charity_and_voluntary_work_web: '',
  client_customer_service_web: '3672785',
  design_and_creative_arts_web: '',
  education_curriculum_teaching_web: '',
  engineers_and_technical_occupations_web: '',
  hr_and_recruitment_web: '',
  healthcare_and_therapy_web: '',
  it_and_technology_web: '3672777',
  insurance_web: '',
  legal_and_councelling_web: '',
  logistic_and_transportation_web: '',
  marketing_and_communications_pr_web: '',
  media_journalism_and_publishing_web: '',
  music_entertainment_and_event_management_web: '',
  production_and_manufacturing_web: '',
  property_and_real_estate_web: '',
  sales_and_trade_web: '',
  science_and_research_web: '',
  security_web: '',
};

export const linkedinEvent = (eventName) => {
  if (LINKEDIN_PARTNER_ID) {
    const conversionId = EVENT_NAME_TO_CONVERSION_ID_MAP[eventName];

    if (conversionId) {
      LinkedInTag.track(conversionId, LINKEDIN_PARTNER_ID);
    }
  }
};
