import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';
import { ILanguageLevelShort, ILanguageShort } from '@nploy/ui/domain';

export const LANGUAGES = 'languages-api';

export const languagesApi = createApi({
  reducerPath: LANGUAGES,
  tagTypes: [LANGUAGES],
  baseQuery: async (args) => {
    const { data } = await axios.request(args);
    return {
      data,
    };
  },
  endpoints: (build) => ({
    getLanguages: build.query<ILanguageShort[], void>({
      query: () => ({
        url: 'list/languages',
        method: 'GET',
        params: {
          all: 1,
        },
      }),
      providesTags: [LANGUAGES],
    }),
    getLanguagesLevels: build.query<ILanguageLevelShort[], void>({
      query: () => ({
        url: 'list/language-levels',
        method: 'GET',
      }),
      providesTags: [LANGUAGES],
    }),
  }),
});

export const { useGetLanguagesQuery, useGetLanguagesLevelsQuery } =
  languagesApi;
