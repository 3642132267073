import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axios } from '@nploy/shared';
import {
  ICandidateCountryFullDto,
  IEmploymentLevelDto,
  IEmploymentTypeDto,
  ISeniorityLevelDto,
} from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';

export const FILTERS_API = 'filters-api';

export const filtersApi = createApi({
  reducerPath: FILTERS_API,
  tagTypes: [FILTERS_API],
  baseQuery: async (args) => {
    const { data } = await axios.request(args);
    return {
      data,
    };
  },
  endpoints: (build) => ({
    getFilters: build.query<
      {
        countries: ICandidateCountryFullDto[];
        employ_levels: IEmploymentLevelDto[];
        employ_types: IEmploymentTypeDto[];
        seniority_levels: ISeniorityLevelDto[];
      },
      void
    >({
      query: () => ({
        url: 'list/general-info',
        method: 'GET',
      }),
      providesTags: [FILTERS_API],
      transformResponse: (baseQueryReturnValue: {
        countries: ICandidateCountryFullDto[];
        employ_levels: IEmploymentLevelDto[];
        employ_types: IEmploymentTypeDto[];
        seniority_levels: ISeniorityLevelDto[];
      }) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { countries, employ_levels, employ_types, seniority_levels } =
          baseQueryReturnValue;

        const newCountries = [...countries].sort(
          (firstCountry, secondCountry) =>
            firstCountry.name > secondCountry.name ? 1 : -1,
        );
        return {
          countries: newCountries,
          employ_levels,
          employ_types,
          seniority_levels,
        };
      },
    }),
    getWorksModels: build.query<IWorkModel[], void>({
      query: () => ({
        url: 'list/work-models',
        method: 'GET',
      }),
      providesTags: [FILTERS_API],
    }),
  }),
});

export const { useGetFiltersQuery, useGetWorksModelsQuery } = filtersApi;
