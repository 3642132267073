import { ICandidateJobDto } from '@nploy/ui-infrastructure';

export const generateFilteredJobs = (
  jobs: ICandidateJobDto[],
  likedIds: number[] = [],
  dislikedIds: number[] = [],
  externalLikedIds: number[] = [],
  externalDislikedIds: number[] = [],
) =>
  jobs.filter(({ id, isExternal }) => {
    if (isExternal) {
      return (
        !externalLikedIds?.includes(id) && !externalDislikedIds?.includes(id)
      );
    }
    return !likedIds?.includes(id) && !dislikedIds?.includes(id);
  });
