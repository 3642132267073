export type SwappedObject<T extends Record<string | number, string | number>> =
  {
    [K in keyof T as T[K] extends string ? number : string]: K extends keyof T
      ? T[K] extends string
        ? string
        : number
      : never;
  };

export const swapObject = <T extends Record<string | number, string | number>>(
  objectValues: T,
): SwappedObject<T> => {
  const newObjectValues = {} as SwappedObject<T>;

  for (const [key, value] of Object.entries(objectValues)) {
    newObjectValues[value] = Number(key) || key;
  }

  return newObjectValues;
};
