import { validateCVSync } from 'validation/*';
import * as actionTypes from '../actions/types';

const initialState = {
  fullComplete: false,
  initialComplete: false,
  partialComplete: false,
  data: {
    documents: [],
    educations: [],
    language_skills: [],
    personal_information: {
      full_name: '',
      photo_url: '',
      phone_number: '',
      city: null,
    },
    skills: [],
    work_experiences: [],
  },
};

export default function cvReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CV_DATA: {
      const { data } = action.payload;

      const { isValid, invalidFields } = validateCVSync(data);

      const initialComplete = data.personal_information.initialComplete === 1;

      const partialComplete =
        (invalidFields.length === 1 && invalidFields[0] === 'skills') ||
        (isValid && !initialComplete);

      return {
        ...state,
        fullComplete: isValid && initialComplete,
        partialComplete,
        initialComplete,
        data: {
          documents: data.documents,
          educations: data.educations,
          language_skills: data.language_skills,
          personal_information: {
            full_name: data.personal_information.full_name,
            photo_url: data.personal_information.photo_url,
            phone_number: data.personal_information.phone_number,
            city: data.personal_information.city,
          },
          skills: data.skills,
          work_experiences: data.work_experiences,
        },
      };
    }
    case actionTypes.SET_INITIAL_CV_COMPLETE:
      return {
        ...state,
        initialComplete: true,
      };
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
